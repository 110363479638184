import {useState} from 'react';
import {useParams} from 'react-router';

import './css/bootstrap.css';
import './css/app.css';
import logo from './images/logo.png';
import {Login} from './components/Login';


function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [loggingIn, setLoggingIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const params = useParams();

  function handleLogin(data) {
    const payload = params;
    payload.login_data = data; 
    setLoggingIn(true);
    setErrorMessage("");
    let res_type = "json";
    let url = "http://fileuploads.repup2.co/api/request_download";
    if (window.location.href.includes("staging")) {
//      url = "https://apistagingfileuploads.repup.co";
        url = "https://asia-south1-prj-rp-d-app-6337.cloudfunctions.net/s3-downloader-api"
    } else {
      url = "https://apifileuploads.repup.co";
    }
    
    fetch(url, {
      "headers": {"content-type": "application/json"},
      "method": "POST",
      "body": JSON.stringify(payload)
    }).then(res => {
          return res.json()
      })
      .then(
        (result) => {
          if (result.success === true) {
            setLoggedIn(true);
            setLoggingIn(false);
            setSuccess(true);
            setErrorMessage("");
            window.location.href=result.url;
          } else {
            setLoggingIn(false);
            setErrorMessage(result.message)
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setErrorMessage("Something went wrong! Please contact support@repup.co");
          setLoggingIn(false);
        }
      )
  }

  return (
    <div className="container">
      <div className="row mt-5">
        <div className="col-md-4 offset-md-4">
          <div className="text-center">
            <img src={logo} height="35" alt="xperium-logo"/>
          </div>
          {!loggedIn && <Login
            handleLogin={(data)=> handleLogin(data)}
            loggingIn={loggingIn}
          />}
          {errorMessage !== "" && <div className="card small p-3 px-5 mt-3 mb-5 text-danger">{errorMessage}</div>}
          {success && <div className="card border-success mt-5 text-center p-3 py-5">
            <i className="fas fa-check-circle fa-3x text-success"></i>
            <div className="mt-4 h6">Your download will start automatically.</div>
          </div>}
        </div>
      </div>
    </div>
  );
}

export default App;
