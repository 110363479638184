import {useState} from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import React from 'react';

export function Login(props) {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [captchaToken, setCaptchaToken] = useState(null);
	const [error, setError] = useState("");

	function handleCaptcha(data) {
		setCaptchaToken(data);
	}
	function handleCaptchaReset() {
		setCaptchaToken(null);
	}

	function handleLogin() {
		if (email.trim() === "" || password.trim() === "") {
				setError("Please enter email address and password.");
		} else if(captchaToken === null) {
			 setError("Please verify captcha.");
		} else {
			props.handleLogin({
	  		email: email,
	  		password: password,
	  		captchaToken: captchaToken
	  	})
	  	handleCaptchaReset();
		}
		setTimeout(()=> setError(""), 3000);
	}
	const recaptchaRef = React.createRef();
 	return (
		<div className="card p-5 mt-5">
			<div>
				<div className="h4 text-center">Sign In</div>
			  <div className="text-small text-primary mb-4 text-center small "> and download file</div>
			  {props.loggingIn ? <div className="text-center">
					<i className="fas fa-spin fa-spinner text-primary fa-2x"></i>
					<div className="mt-3 h6">Please wait...</div>
				</div> : <div>
				  <div className="form-input-box lg icon mb-4">
				  	<i className="fas fa-envelope"></i>
				    <input placeholder="Email Address" value={email} onChange={(e)=> setEmail(e.target.value)} type="text" className="form-input" />
				  </div>
				  <div className="form-input-box lg icon  mb-4">
				  	<i className="fas fa-lock"></i>
				    <input placeholder="Password" value={password} onChange={(e)=> setPassword(e.target.value)} type="password" className="form-input" />
				  </div>
				  {/*<div className="g-recaptcha" data-callback="onCaptchaChange" data-sitekey="6LeRvdIaAAAAACnm1USs7uSwporqrm0Yr09gWdpD"></div>*/}
				  <ReCAPTCHA
		        ref={recaptchaRef}
		        sitekey="6LeRvdIaAAAAACnm1USs7uSwporqrm0Yr09gWdpD"
		        onExpired={handleCaptchaReset}
		        onErrored={handleCaptchaReset}
		        onChange={handleCaptcha}
		      />
				  <div className="d-grid mt-4">
				  	<button onClick={()=> handleLogin()} className="btn-repup btn-repup-lg btn-repup-gradient">
				  		<i className="fas fa-sign-in-alt"></i>
				  		Sign In
				  	</button>
				  </div>
				  {error !== "" && <div className="mt-4 small text-muted">
				  	<i className="fas fa-exclamation-triangle text-warning"/>&nbsp;{error}
				  </div>}
				</div>}
			</div>
		</div>
	)
}